import React from 'react'
import { TestimonialsData } from '@util/homeCardData'
import "./Testimonials.scss"

export default function TestimonialsPage() {
  const Title = ({ className, children }) => {
    return (
      <p className={`smart-text text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl uppercase  font-thin tracking-wider ${className}`}>
        {children}
      </p>
    )
  }

  return (
    <div className='testimonials flex-1 w-full flex justify-center items-center'>
      <div className='container px-0 xs:px-12 sm:px-4 md:px-28 lg:px-48 xl:px-8 justify-center flex mx-auto items-center pb-60 flex-col mt-16 md:mt-20 lg:mt-24 2xl:mt-28'>
        <div className='flex flex-col items-center mb-10 sm:mb-0'>
          <Title>
            Testimonials
          </Title>
          <p className={`uppercase text-center text-lg sm:text-xl xl:text-2xl leading-7 sm:leading-9 xl:leading-10 text-white font-montserrat font-semibold mt-2`}>
            what our partners think!
          </p>
          <div className='bg-custom-green-600 w-12 h-1'></div>
        </div>
        <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-8 gap-y-10 mt-5 sm:mt-20'>
          {TestimonialsData.map((data, idx) => (
            <div key={idx} className="relative text-white overflow-hidden rounded-b-2xl">
              <div className='relative flex items-center w-full justify-center'>
                <img src={data.avatar} alt="avatar" className='w-2/3' />
                <img src="/testimonials/bg_box_header.png" alt='box-header'  className='absolute bottom-0 w-full' />
              </div>
              <div className='flex flex-col items-center gap-1 h-full 2xl:gap-2 bg-custom-gray-100 '>
                <div className='flex flex-col items-center px-4 gap-1 2xl:gap-2 font-montserrat font-semibold pt-4 pb-8'>
                  <div className='flex flex-col items-center gap-1'>
                    <img src='/testimonials/img_stars.png' alt='stars' className='w-1/2' />
                    <div className='flex flex-wrap justify-center items-center'>
                      <a href={data.twitter} target="_blank" rel="noreferrer" className='w-4 flex-shrink-0 mx-1'>
                        <img src='/testimonials/img_twitter.png' alt='twitter' className='' />
                      </a>
                      <p className='xs:text-xl sm:text-base uppercase 2xl:text-lg text-center'>
                        {data.name}
                      </p>
                      <p className='uppercase xs:text-xs sm:text-sm xl:text-xs opacity-50 mx-1'>
                        ({data.role})
                      </p>
                    </div>
                    <div className='h-1 w-10/12 bg-custom-green-600'></div>
                  </div>
                  <p className='xs:text-lg mt-2 sm:text-base xl:text-base 2xl:text-lg text-center'>
                    {data.feedback}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
