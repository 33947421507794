import React from "react"
import Layout from "@components/layout/Layout/Layout"
import TestimonialsPage from "@partials/Testimonials/Testimonials"

// markup
const Testimonials = () => (
  <Layout title="Masterbrews | Partner Testimonials" contentClassName="px-0 max-w-full">
    <TestimonialsPage />
  </Layout>
)

export default Testimonials
